<template>
    <div class="leftMenu">
        <el-menu mode="vertical" background-color="#000" active-text-color="blue" text-color="#fff" default-active="0"
            popper-effect="light" class="el-menu-vertical-demo" style="border: none !important;">
            <el-menu-item :index="String(index)" v-for="(item, index) in lists" :key="item.id"
                @click="somClick(item.id, item.token)">
                <template #title>
                    <div style="display: flex;width: 100%;position: relative;">
                        <div>{{ item.role_realname }}· {{ item.role_dynasty }}</div>
                        <div v-if="item.isRead == false"
                            style="position: absolute;top: 5px;right: 0;width: 10px;height: 10px;background: red;border-radius: 50%;">
                        </div>
                    </div>
                </template>
            </el-menu-item>
        </el-menu>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lists: []
        }
    },
    methods: {

        //获取列表
        getLists(list) {
          this.lists = list;
        },
        //点击机器人列表
        somClick(id, token) {
            this.$emit("somClick", {
                "token": token,
                "id": id
            });
        }
    }
}
</script>
<style>
.leftMenu {
    width: 160px;
    background: #000;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    height: 100%;
}

.leftMenu::-webkit-scrollbar-thumb {
    width: 3px;
    background: #fff;
    border-radius: 10px;
}

.leftMenu::-webkit-scrollbar {
    background: #000;
    width: 5px;
}
</style>