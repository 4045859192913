<template>
    <div class="content">
        <div class="emptyBox" v-if="isShow == false">
            <img src="../assets/empty.png" class="empty" alt="">
            <p style="text-align: center;">暂无内容</p>
        </div>
        <div class="chat" v-else>
            <div class="chatHead">
                <p>{{ userData.role_realname }}·{{ userData.role_dynasty }}</p>
            </div>
            <div class="chatContainer" id="chatContainer" @scroll="msgScroll">
                <template v-for="(item, index) in msgList" :key="index">
                    <div class="left" v-if="item.user_id == userData.user_id">
                        <div class="leftItem">
                            <div>
                                <img :src="item.avatar" class="avatar" alt="">
                            </div>
                            <!-- 礼物 -->
                            <div class="chatMsg radiusLeft" v-if="item.type == 'gift'" style="margin-left: 15px;">
                                <img :src="item.content" style="width: 100px;height: 100px;" alt="">
                            </div>
                            <!-- 图片 -->
                            <div class="chatMsg radiusLeft" v-if="item.type == 'image'"
                                style="margin-left: 15px;background: #fff;color: #fff;width: 200px;height: 200px;">
                                <img @load="imgLoad" :src="item.content"
                                    style="border-radius: 10px;width: 200px;height: 200px;">
                            </div>
                            <!-- 文本 -->
                            <div class="chatMsg radiusLeft" v-if="item.type == 'text'"
                                style="margin-left: 15px;background: #fff;color: #333;margin-top: 10px;">
                                {{ item.content }}
                            </div>
                            <!-- 房间 -->
                            <div class="chatMsg radiusLeft" v-if="item.type == 'play'"
                                style="margin-left: 15px;background: #fff;color: #333;margin-top: 10px;">
                                {{ item.content }} <span style="color: blue;">《房间邀请》</span>
                            </div>
                        </div>
                    </div>
                    <div class="right" v-else>
                        <div class="leftItem">
                            <!-- 礼物 -->
                            <div class="chatMsg radiusRight" v-if="item.type == 'gift'" style="margin-right: 15px;">
                                <img :src="item.content" style="width: 100px;height: 100px;" alt="">
                            </div>
                            <!-- 图片 -->
                            <div class="chatMsg radiusRight" v-if="item.type == 'image'"
                                style="margin-right: 15px;width: 200px;height: 200px;">
                                <img @load="imgLoad" :src="item.content" alt=""
                                    style="border-radius: 10px;width: 100%;height: 100%;">
                            </div>
                            <!-- 文本 -->
                            <div class="chatMsg radiusRight" v-if="item.type == 'text'"
                                style="margin-right: 15px;background: pink;color: #fff;margin-top: 10px;">
                                {{ item.content }}
                            </div>
                            <!-- 房间 -->
                            <div class="chatMsg radiusRight" v-if="item.type == 'play'"
                                style="margin-right: 15px;background: pink;color: #fff;margin-top: 10px;">
                                {{ item.content }}<span style="color: blue;">《房间邀请》</span>
                            </div>
                            <div>
                                <img :src="item.avatar" class="avatar" alt="">
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="chatFooter">
                <div class="myInputBox">
                    <input type="text" placeholder="请输入内容" v-model="inputValue" @keyup.enter="enterChange" class="myInput">
                </div>
                <div style="display: flex;align-items: center;margin-left: 30px;">
                    <el-button type="danger" @click="enterChange">发送</el-button>
                    <!-- <div style="margin: 0px 10px;" @click="emotePopup = true">
                        <el-button type="primary" icon="Football" />
                    </div> -->
                    <div @click="giftPopup = true" style="margin: 0px 10px;">
                        <el-button type="warning" icon="Sugar" />
                    </div>
                    <div style="margin-left: 10px;">
                        <el-upload action="https://yaji.suoeryoude.cn/api/hey/index/upload" name="file" :multiple="false"
                            :headers="headers" method="post" :show-file-list="false" :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <el-button type="danger" icon="CirclePlus" />
                        </el-upload>
                    </div>
                </div>
            </div>
        </div>
        <!-- <el-dialog v-model="emotePopup" title="表情" width="30%">
            <div class="emoteBox">
                <div v-for="(item, index) in emoteList" :key="index" @click="addEmote(item)" class="emoteItem">{{ item }}
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="emotePopup = false">
                        关闭
                    </el-button>
                </span>
            </template>
        </el-dialog> -->
        <el-dialog v-model="giftPopup" title="礼物" width="30%">
            <div class="giftBox">
                <div v-for="(item, index) in giftList" :key="index" class="giftItem" @click="sendGift(item)">
                    <img :src="item.image" class="giftImg" alt="" v-if="item.status == 'normal'">
                    <div v-if="item.status == 'normal'">{{ item.title }}</div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="giftPopup = false">
                        关闭
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    name: "MyContainer",
    data() {
        return {
            userData: [],
            isShow: false,
            system_child_user_id: '',
            childId: "",
            userConent: [],
            userId: "",
            inputValue: '',
            emotePopup: false,
            giftPopup: false,
            headers: {
                "token": localStorage.getItem("token")
            },
            msgList: [],
            giftList: [],
            page: 1,
            // emoteList: ['😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍',
            //     '😘', '🥰', '😗', '😙', '🥲', '😚', '🙂', '🤗', '🤩', '🤔', '🫡', '🤨', '😐', '😑', '😶',
            //     '🫥', '😶‍🌫️', '🙄', '😏', '😣', '😥', '😮', '🤐', '😯', '😪', '😫', '🥱', '😴', '😌',
            //     '😛', '😜', '😝', '🤤', '😒', '😓', '😔', '😕', '🫤', '🙃', '🫠', '🤑', '😲', '☹️', '🙁',
            //     '😖', '😞', '😟', '😤', '😢', '😭', '😦', '😧', '😨', '😩', '🤯', '😬', '😮‍💨', '😰',
            //     '😱', '🥵', '🥶', '😳', '🤪', '😵', '😵‍💫', '🥴', '😠', '😡', '🤬', '😷', '🤒', '🤕',
            //     '🤢', '🤮', '🤧', '😇', '🥳', '🥸', '🥺', '🥹', '🤠', '🤡', '🤥', '🤫', '🤭', '🫢', '🫣',
            //     '🧐', '🤓', '😈', '👿', '👹', '👺', '💀', '☠️', '👻', '👽', '👾', '🤖', '💩', '😺', '😸',
            //     '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🙈', '🙉', '🙊', '🐵', '🐶', '🐺', '🐱', '🦁',
            //     '🐯', '🦒', '🦊', '🦝', '🐮', '🐷', '🐗', '🐭', '🐹', '🐰', '🐻', '🐻‍❄️', '🐨', '🐼',
            //     '🐸', '🦓', '🐴', '🦄', '🐔', '🐲', '🐽', '🐾', '🐒', '🦍', '🦧', '🦮', '🐕‍🦺', '🐩',
            //     '🐕', '🐈', '🐈‍⬛', '🐅', '🐆', '🐎', '🦌', '🦬', '🦏', '🦛', '🐂', '🐃', '🐄', '🐖', '🐏',
            //     '🐑', '🐐', '🐪', '🐫', '🦙', '🦘', '🦥', '🦨', '🦡', '🐘', '🦣', '🐁', '🐀', '🦔', '🐇',
            //     '🐿️', '🦫', '🦎', '🐊', '🐢', '🐍', '🐉', '🦕', '🦖', '🦦', '🦈', '🐬', '🦭', '🐳', '🐋',
            //     '🐟', '🐠', '🐡', '🦐', '🦑', '🐙', '🦞', '🦀', '🐚', '🪸', '🦆', '🐓', '🦃', '🦅', '🕊️',
            //     '🦢', '🦜', '🦩', '🦚', '🦉', '🦤', '🪶', '🐦', '🐧', '🐥', '🐤', '🐣', '🦇', '🦋', '🐌',
            //     '🐛', '🦟', '🪰', '🪱', '🦗', '🐜', '🪳', '🐝', '🪲', '🐞', '🦂', '🕷️', '🕸️', '🦠',
            //     '🧞‍♀️', '🧞‍♂️', '🧞', '🧟‍♀️', '🧟‍♂️', '🧟', '🧌', '🗣️', '👤', '👥', '🫂', '👁️', '👀',
            //     '🦴', '🦷', '👅', '👄', '🫦', '🧠', '🫀', '🫁', '🦾', '🦿', '👣', '🤺', '⛷️'
            // ]
        }
    },
    mounted() {
        var that = this;
        this.getGiftList();
        this.$webSocketObj.addEventListener("message", function (event) {
            var data = JSON.parse(event.data);
            if (data.cate == 'chat') {
                console.log(data)
                if (that.userData.length != 0) {
                    if (data.data.from_user_id == that.userData.user_id) {
                        that.getMsgList(that.userData)
                    } else {
                        that.$emit("socketMessage", data.data)
                    }
                } else {
                    that.$emit("socketMessage", data.data)
                }

            }
        });
    },
    methods: {
        msgScroll() {
            this.$nextTick(function () {
                let chat = this.$el.querySelector("#chatContainer");
                if (chat.scrollTop <= 0) {
                    this.page++;
                    this.getMsgList(this.userData, this.page)
                }
            })
        },
        setChildId(id) {
            this.childId = id;
            this.page = 1;
            this.senMsg("read", "")
        },
        setSystem_child_user_id(id) {
            this.system_child_user_id = id;
        },
        //获取聊天记录
        getMsgList(data, page = 1) {
            this.userData = data;
            this.$axios.post("chat_message/system_children_user_history", {
                "to_user_id": data.user_id,
                "page": page,
                "from_user_id": data.from_user_id
            }).then(res => {
                if (res.data.code == 1) {
                    this.isShow = true;
                    var arr = [];
                    if (page > 1) {
                        arr = res.data.data;
                        arr.push(...this.msgList)
                        arr.sort(function (a, b) {
                            return b.createtime > a.createtime ? -1 : 1;
                        })
                        this.msgList = arr;
                    } else {
                        arr = res.data.data;
                        arr.sort(function (a, b) {
                            return b.createtime > a.createtime ? -1 : 1;
                        })
                        this.msgList = arr;
                        this.scrollBottom()
                    }
                }
            });
        },
        clearMsgList() {
            this.msgList = [];
            this.page = 1;
        },
        sendGift(item) {
            this.$axios.post("user_gift/give_gift", {
                "gift_id": item.id,
                "receiver_user_id": this.userData.user_id,
                "nums": 1
            }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success(res.data.msg);
                    this.senMsg({
                        content: item.image,
                        gift_id: item.id,
                        gift_svga: item.url,
                        type: 'gift',
                    })
                    this.scrollBottom()
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        getGiftList() {
            var that = this;
            this.$axios.get("gift/lists").then(res => {
                if (res.data.code == 1) {
                    that.giftList = res.data.data
                }
            });
        },
        enterChange() {
            if (this.inputValue == '') {
                ElMessage({
                    message: "请输入内容",
                    type: 'warning'
                })
            } else {
                this.senMsg({
                    content: this.inputValue,
                    type: "text",
                });
                this.inputValue = ''
            }
        },
        senMsg(data) {
            let that = this;
            var obj = {
                cate: "chat",
                code: 1,
                msg: "success",
                data: {
                    from_user_id: this.userData.from_user_id,
                    to_user_id: this.userData.user_id,
                    message: data
                }
            }
            let objStr = JSON.stringify(obj);
            this.$webSocketObj.send(objStr);
            console.log("发送信息成功")
            this.getMsgList(this.userData)
            this.scrollBottom()
        },
        // senMsg(type, data, gift_id = "", cate = "3") {
        //     var obj = {
        //         system_child_user_id: this.system_child_user_id,
        //         to_user_id: this.childId,
        //         type: type,
        //         cate: cate,
        //         data: data,
        //         gift_id: gift_id
        //     }
        //     let objStr = JSON.stringify(obj);
        //     this.$webSocketObj.send(objStr);
        //     console.log("发送信息成功")
        //     this.getMsgList(this.userData)
        //     this.scrollBottom()
        // },
        scrollBottom() {
            if (this.isShow == false) {
                return;
            }
            this.$nextTick(function () {
                let chat = this.$el.querySelector("#chatContainer");
                chat.scrollTop = chat.scrollHeight;
            })
        },
        imgLoad() {
            this.scrollBottom()
        },
        addEmote(item) {
            this.inputValue += item;
        },
        handleAvatarSuccess(res, file) {
            this.senMsg({
                content: res.data.fullurl,
                type: "image",
            })
            this.$nextTick(function () {
                let chat = this.$el.querySelector("#chatContainer");
                chat.scrollTop = chat.scrollHeight;
            })
        },
        beforeAvatarUpload(file) {
            var falg = false;
            const isJPG = file.type === 'image/jpg';
            const isPNG = file.type === 'image/png';
            if (file.type != 'image/jpeg' && file.type != 'image/png') {
                this.$message.error('请上传图片');
            } else {
                falg = true
            }
            return falg;
        }
    }
}
</script>

<style scoped>
.content {
    width: calc(100% - 160px - 160px);
    height: 100%;
    margin: 0 auto;
}

.emptyBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
}

.radiusLeft {
    border-radius: 15px;
    border-top-left-radius: 0px !important;
}

.radiusRight {
    border-radius: 15px;
    border-top-right-radius: 0px !important;
}

.empty {
    width: 300px;
    height: 300px;
}

.chat {
    height: 100%;
}

.chatHead {
    height: 50px;
    padding-left: 20px;
    background: #fff;
    line-height: 50px;
}

.chatContainer {
    height: 85%;
    padding: 30px;
    box-sizing: border-box;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    overflow-y: scroll;
}

.right {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.leftItem {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
}

.chatMsg {
    padding: 10px 15px;
    font-size: 15px;
    /* margin-top: 10px; */
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    float: left;
}

.chatFooter {
    background: #fff;
    height: calc(100% - 85% - 50px);
    display: flex;
    align-items: center;
    padding: 0px 20px;
    width: 100%;
    /* justify-content: space-between; */
    box-sizing: border-box;
}

.myInputBox {
    width: 50%;
}

.myInput {
    height: 35%;
    width: 100%;
    font-size: 15px;
    padding: 5px;
}

.emoteBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
}

.giftBox {
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
}

.emoteItem {
    font-size: 30px;
    cursor: pointer;
}

.giftItem {
    cursor: pointer;
    width: 100px;
    height: 100px;
    display: inline-block;
    text-align: center;
}

.giftImg {
    width: 100px;
    height: 100px;
}
</style>