<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    document.addEventListener('touchmove', function (ev) {
      ev.preventDefault();
    }, { passive: false });
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

body {
  background: #f1f4fd;
}
</style>
