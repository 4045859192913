<template>
    <div class="home">

        <Head></Head>
        <div class="containerBody">
            <LeftMenu :menuList="menuData" @somClick="somClick" ref="leftMenu"></LeftMenu>
            <TwoMenu @twoMenuclickItem="twoMenuclickItem" @scrollBottom="scrollBottom" ref="twoMenu"></TwoMenu>
            <Container @socketMessage="socketMessage" ref="container"></Container>
        </div>
    </div>
</template>
<script>
import Head from "../components/Head.vue"
import LeftMenu from "../components/LeftMenu.vue"
import TwoMenu from "../components/TwoMenu.vue"
import Container from "../components/Container.vue"
import { ElLoading } from 'element-plus'
export default {
    name: "MyHome",
    components: {
        Head,
        LeftMenu,
        TwoMenu,
        Container
    },
    data() {
        return {
            menuData: [],
            loading: true,
            p_user_id: "",
            twoMenuList: [],
            menuId: "",
            page: 1
        }
    },
    mounted: function () {
        this.getLists()
    },
    methods: {
        scrollBottom() {
            this.page++;
            this.getTwoMenuList(this.menuId);
        },
        //获取列表
        getLists() {
            this.loading = ElLoading.service({ fullscreen: true })
            this.$axios.get("user/system_children_list").then(res => {
                if (res.data.code == 1) {
                    this.menuData = res.data.data;
                    this.$refs.leftMenu.getLists(this.menuData)
                    this.menuId = res.data.data[0].id
                    this.getTwoMenuList(this.menuId)
                }
            });
        },
        //点击机器人
        somClick(somData) {
            this.token = somData.token;
            this.menuId = somData.id;
            this.isShow = false;
            this.twoMenuList = [];
            this.page = 1;
            this.getTwoMenuList(this.menuId);
        },
        //初始化机器人好友列表
        getTwoMenuList(id) {
            this.$axios.post("chat/system_chat_list", {
                from_user_id: id,
                page: this.page
            }).then(res => {
                this.loading.close();
                if (res.data.code == 1) {
                    this.twoMenuList = [...this.twoMenuList, ...res.data.data.list];
                    this.$refs.twoMenu.getList(this.twoMenuList, this.page)
                    if (this.page == 1) {
                        this.p_user_id = res.data.data.list[0].from_user_id
                    }
                }
            });
        },
        //点击好友列表
        twoMenuclickItem(item, redFlag) {
            item.from_user_id = this.p_user_id;
            if (redFlag) {
                this.menuData.forEach((menuDataItem, index) => {
                    if (menuDataItem.id == Number(item.from_user_id)) {
                        this.menuData[index].isRead = true;
                    }
                })
            }
            this.$refs.container.getMsgList(item)
            this.$refs.container.clearMsgList()
        },
        socketMessage(data) {
            this.menuData.forEach((menuDataItem, index) => {
                if (menuDataItem.id == Number(data.to_user_id)) {
                    this.menuData[index].isRead = false;
                }
            })
            if (Number(data.to_user_id) == this.menuId) {
                this.getTwoMenuList(this.menuId)
            }
        }
    }
}
</script>
<style scoped>
.containerBody {
    height: calc(100vh - 80px);
    margin-top: 80px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    touch-action: pan-y;
    -webkit-overflow-scrolling: touch;
}
</style>