<template>
    <div class="head">
        <div class="headLeft">
            <img src="../assets/logo.png" class="logo" alt="">
            <strong style="margin-left: 10px;">雅集</strong>
        </div>
        <el-button type="danger" @click="exit">退出</el-button>
    </div>
</template>
<script>
export default {
    name: "myHead",
    methods: {
        exit() {
            location.reload();
            this.$webSocketObj.close()
            clearInterval(this.$webSocketObj.sendPingPong)
            localStorage.removeItem("token");
            localStorage.removeItem("user_id");
            this.$router.replace("/")
        }
    }
}
</script>
<style>
.head {
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 30px;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}

.headLeft {
    display: flex;
    align-items: center;
    color: #fff;
}

.logo {
    width: 30px;
    height: 30px;
}
</style>