import { createApp } from 'vue'
import router from "../router/index"
import VueSocketIO from 'vue-socket.io'
import socketIO from 'socket.io-client'
import newSocket from "./api/newSocket"
import axios from "./api/axios"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'

const app = createApp(App);
app.use(router)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
app.config.globalProperties.$axios = axios
app.config.globalProperties.$webSocketObj = newSocket;
if (localStorage.getItem("token") == null) {
    router.replace("/")
}