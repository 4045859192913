import axios from "axios";
const API = axios.create({
    url:"",
    baseURL: "https://yaji.suoeryoude.cn/api/hey/",
    headers: {
        "token": localStorage.getItem("token") == null ? '' : localStorage.getItem("token")
    },
})


export default API