//socket
var token = localStorage.getItem("token");
var user_id = localStorage.getItem("user_id");
var session_id = '';
const webSocketObj = new WebSocket(`wss://yaji.suoeryoude.cn/websocket?token=${token}&user_id=${user_id}`);
if (user_id != null) {
    webSocketObj.onopen = function (event) {
        console.log("socket建立链接");
        sendPingPong()

    };
}

function sendPingPong() {
    var that = this;
    let obj = {
        type: 'ping'
    };
    let objStr = JSON.stringify(obj);
    webSocketObj.sendPingPong = setInterval(() => {
        webSocketObj.send(objStr);
        console.log("发送心跳！")
    }, 3000)
}

export default webSocketObj