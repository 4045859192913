<template>
    <div class="twoMenu">
        <p class="title">选择用户{{ lists.length }}</p>
        <div class="scroll" id="scroll" ref="scroll" @scroll="handleScroll">
            <div @click="clickNavItem(item)" v-for="(item, index) in lists" :key="index"
                style="display: flex;width: 100%;position: relative;height: 50px;text-align: center;line-height: 50px;display: flex;align-items: center;justify-content: center;cursor: pointer;">
                <div :style="{ color: item.user_id == selectUser_id ? 'blue' : '#fff' }"> {{ item.role_realname }}·{{
                    item.role_dynasty }}</div>
                <div v-if="item.isRead == false"
                    style="position: absolute;top: 5px;right: 0;width: 10px;height: 10px;background: red;border-radius: 50%;">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lists: [],
            p_user_id: '',
            selectUser_id: ''
        }
    },
    methods: {
        handleScroll(e) {
            var scrollTop = e.target.scrollTop
            var scrollHeight = e.target.scrollHeight
            var clientHeight = e.target.clientHeight
            if (scrollTop + clientHeight >= scrollHeight) {
                this.$emit("scrollBottom")
            }
        },
        getList(data, page) {
            if (page == 1) {
                var scroll = document.getElementById("scroll")
                scroll.scrollTo(0, 0)
            }
            this.list = [];
            this.lists = data;
            this.p_user_id = data.p_user_id
        },
        setTips(id) {
            this.list.custom_user_data.forEach((val, index) => {
                if (val.user_id == id) {
                    val.isRead = false;
                }
            })
        },
        clickNavItem(item) {
            this.selectUser_id = item.user_id
            this.lists.forEach((val, index) => {
                if (val.user_id == item.user_id) {
                    val.isRead = true;
                }
            })
            var redArr = this.lists.filter((item, index) => {
                return item.isRead == false;
            })
            this.$emit("twoMenuclickItem", item, redArr.length == 0 ? true : false)
        }
    }
}
</script>
<style>
.twoMenu {
    width: 160px;
    text-align: center;
    border-left: 1px solid #eee;
    background: #000;
    color: #fff;
}

.title {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
}

.scroll {
    height: calc(100% - 50px);
    overflow-y: scroll;
}

.scroll::-webkit-scrollbar-thumb {
    width: 3px;
    background: #fff;
    border-radius: 10px;
}

.scroll::-webkit-scrollbar {
    background: #000;
    width: 5px;
}
</style>