<template>
    <div class="container">
        <div class="login-screen">
            <div class="loginHead" style="background: #899fe1;">
                <img src="../assets/images/login-head.png" style="width: 100%;" alt="">
            </div>
            <div class="lognForm">
                <p style="text-align: center;margin-bottom: 20px;">
                    <img class="avatar" src="../assets/avatar.png" alt="">
                </p>
                <el-input placeholder="用户名" v-model="phone" class="input-with-select">
                    <template #prepend>
                        <el-icon>
                            <Avatar />
                        </el-icon>
                    </template>
                </el-input>
                <el-input style="margin-top: 20px;" v-model="password" type="password" placeholder="密码"
                    class="input-with-select">
                    <template #prepend>
                        <el-icon>
                            <Unlock />
                        </el-icon>
                    </template>
                </el-input>
                <el-button @click="openHome" type="primary" size="large" :loading="false"
                    style="width: 100%;margin-top: 20px;">登录</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyLogin',
    data() {
        return {
            phone: '',
            password: ''
        }
    },
    beforeCreate() {
        const token = localStorage.getItem("token");
        if (token != null) {
            this.$router.replace("/home")
        }
    },
    methods: {
        openHome() {
            if (this.phone == '') {
                this.$message.error('输入手机号');
                return;
            }
            if (this.password == '') {
                this.$message.error('输入密码');
                return;
            }
            this.$axios.post("user/system_smslogin", {
                mobile: this.phone,
                code: this.password,
                event: "register"
            }).then(res => {
                if (res.data.code == 1) {
                    localStorage.setItem("token", res.data.data.token)
                    localStorage.setItem("user_id", res.data.data.user_id);
                    location.reload();
                    this.$nextTick(() => {
                        this.$router.push("/home");
                        location.reload()
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        }
    }
}
</script>
<style scoped>
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.login-screen {
    max-width: 430px;
    margin: 0 auto;
    margin-top: 100px;
    border-radius: 15px;
    overflow: hidden;
}

.lognForm {
    padding: 40px 30px;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    margin-top: -10px;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #fff;
    margin-top: -93px;
}
</style>